// extracted by mini-css-extract-plugin
export var container = "style-module--container--3W2Sc";
export var dot = "style-module--dot--bc61+";
export var fixed = "style-module--fixed--KVYxC";
export var footerApp = "style-module--footerApp--tYjHC";
export var footerLists = "style-module--footerLists--0t4eO";
export var footerRay = "style-module--footerRay--2hu7H";
export var logo = "style-module--logo--dV4Hl";
export var menu = "style-module--menu--Q6U6+";
export var menuButtons = "style-module--menuButtons--oE9pm";
export var menuLogo = "style-module--menuLogo--N8XuD";
export var switchIcon = "style-module--switchIcon--VJ12L";
export var wallets = "style-module--wallets--9nxYN";