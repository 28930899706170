exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-audit-js": () => import("./../../../src/pages/audit.js" /* webpackChunkName: "component---src-pages-audit-js" */),
  "component---src-pages-cardano-web-3-js-js": () => import("./../../../src/pages/cardano-web3-js.js" /* webpackChunkName: "component---src-pages-cardano-web-3-js-js" */),
  "component---src-pages-cardanolist-js": () => import("./../../../src/pages/cardanolist.js" /* webpackChunkName: "component---src-pages-cardanolist-js" */),
  "component---src-pages-coinmarketcap-js": () => import("./../../../src/pages/coinmarketcap.js" /* webpackChunkName: "component---src-pages-coinmarketcap-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-graph-js": () => import("./../../../src/pages/graph.js" /* webpackChunkName: "component---src-pages-graph-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minterr-js": () => import("./../../../src/pages/minterr.js" /* webpackChunkName: "component---src-pages-minterr-js" */),
  "component---src-pages-pad-js": () => import("./../../../src/pages/pad.js" /* webpackChunkName: "component---src-pages-pad-js" */),
  "component---src-pages-pigz-js": () => import("./../../../src/pages/pigz.js" /* webpackChunkName: "component---src-pages-pigz-js" */),
  "component---src-pages-ray-foundation-js": () => import("./../../../src/pages/ray-foundation.js" /* webpackChunkName: "component---src-pages-ray-foundation-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-stake-js": () => import("./../../../src/pages/stake.js" /* webpackChunkName: "component---src-pages-stake-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-swap-js": () => import("./../../../src/pages/swap.js" /* webpackChunkName: "component---src-pages-swap-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-pages-xdiamond-js": () => import("./../../../src/pages/xdiamond.js" /* webpackChunkName: "component---src-pages-xdiamond-js" */),
  "component---src-pages-xray-index-js": () => import("./../../../src/pages/xray/index.js" /* webpackChunkName: "component---src-pages-xray-index-js" */),
  "component---src-pages-xray-swap-event-js": () => import("./../../../src/pages/xray/swap-event.js" /* webpackChunkName: "component---src-pages-xray-swap-event-js" */),
  "component---src-pages-xray-tokenomics-js": () => import("./../../../src/pages/xray/tokenomics.js" /* webpackChunkName: "component---src-pages-xray-tokenomics-js" */)
}

